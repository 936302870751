:root {
  /* Primary colors */
  --bacon-primary: #E27B36;     /* Slightly brighter main bacon color */
  --bacon-dark: #8B4513;        /* Keep this rich brown */
  --bacon-light: #FFB38A;       /* Lighter, fresher bacon color */
  --bacon-accent: #FF7043;      /* Crispy edge color */
  
  /* Supporting colors */
  --bacon-bg-light: #FFF5EE;    /* Very light peachy background */
  --bacon-shadow: rgba(139, 69, 19, 0.15); /* Softer shadows */
  
  /* Text colors */
  --text-primary: #2D2D2D;      /* Softer than pure black */
  --text-secondary: #666666;     /* Medium gray for secondary text */
}

/* General styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  overflow: hidden; /* Prevent scrolling on the main app */
}

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Header styles */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  z-index: 1000;
  background: white;
}

.main-nav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.main-nav li {
  margin-left: 20px;
}

.main-nav a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 500;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
/* Search bar styles */
.search-bar-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1500;
  width: 90%;
  max-width: 600px;
  margin: 10px auto;
}

.search-bar {
  display: flex;
  width: 100%;
  border-radius: 25px;
  overflow: hidden;
}

.search-bar input {
  flex-grow: 1;
  padding: 10px 15px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-right: none;
  border-radius: 25px 0 0 25px;
  transition: border-color 0.3s ease;
  height: 44px;
  box-sizing: border-box;
}

.search-bar input:focus {
  outline: none;
  border-color: #f4a460;
}

.search-button {
  padding: 0 10px;
  background-color: #ccc;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 25px 25px 0;
}

.search-button.has-results {
  background-color: #f4a460;
}

/* Search Results Container */
.search-results-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  z-index: 2000;
}

/* Helper Text */
.search-helper-text {
  padding: 8px 12px;
  color: var(--bacon-dark);
  font-size: 0.8rem;
  font-weight: 800;
  background: rgb(248, 248, 248);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Suggestions List */
.search-suggestions {
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  background: white;
  border-left: 2px solid #f4a460;
  border-right: 2px solid #f4a460;
  border-bottom: 2px solid #f4a460;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.search-suggestions li {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-align: left;
}

.search-suggestions li:hover {
  background-color: #fff0e0;
}

.suggestion-item {
  text-align: left;
}

/* Collapsed Search Bar */
.search-bar-collapsed {
  background: #f4a460;
  border-radius: 25px;
  padding: 6px;
  transition: all 0.3s ease;
  cursor: pointer;
  margin-left: auto;
  width: fit-content;
  display: flex;
  align-items: center;
}

.search-bar-collapsed:hover {
  background: #e27b36;
  transform: translateY(-1px);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.search-bar-collapsed .search-button {
  background: none;
  padding: 4px;
}

.search-bar-collapsed .search-button img {
  filter: brightness(0) invert(1);
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .search-bar-container {
    width: 95%;
  }
}

.clear-cache-button {
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9em;
}

.clear-cache-button:hover {
  background-color: #c82333;
}

.dev-link {
  color: #ffffff;
  text-decoration: none;
  font-size: 0.9em;
}

.dev-link:hover {
  text-decoration: underline;
}

/* Welcome message styles */
.welcome-message {
  text-align: center;
  padding: 60px 20px;
  max-width: 600px;
  margin: 20px auto;
  background: #fff;  /* Clean white background */
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(139, 69, 19, 0.05);  /* Subtle shadow */
}

.welcome-message h1 {
  color: var(--text-primary);
  font-size: 1.0rem;
  line-height: 1.2;
  margin-bottom: 30px;
  font-weight: 800;
  letter-spacing: -0.02em;
}

.welcome-message .highlight {
  background: linear-gradient(120deg, var(--bacon-primary), var(--bacon-accent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 800;
}

.welcome-message .subtitle {
  color: var(--text-secondary);
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 50px;
  font-weight: 400;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
}

.welcome-message .credits {
  margin-top: 60px;
  padding-top: 30px;
  border-top: 1px solid rgba(139, 69, 19, 0.08);
}

.welcome-message a {
  color: var(--bacon-primary);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;
  position: relative;
}

.welcome-message a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: var(--bacon-primary);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.welcome-message a:hover::after {
  transform: scaleX(1);
}

/* Visualization styles */
.visualization-page {
  padding-top: 50px;  /* Add padding to account for fixed header */
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);  /* Adjust height to account for padding */
  overflow: hidden;
}

.visualization-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  padding: 0 15px;  /* Add horizontal padding */
}

.visualization-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 500px;  /* Fixed height */
  margin: 0 auto;
  z-index: 1;
}

.visualization-container > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.visualization-container canvas {
  width: 100% !important;
  height: 100% !important;
}

/* Additional styles for other details */
.bottom-modal-content p {
  margin: 5px 0;
  color: #666; /* Changed to gray */
  font-size: 0.9rem;
}

.bottom-modal-content strong {
  color: #8B4513;
}

/* Reset FAB styles */
.reset-fab {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(226, 123, 54, 0.8);
  color: white;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  z-index: 1000;
}

.reset-fab:hover {
  background-color: rgba(210, 105, 30, 0.9);
}

.reset-fab svg {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

/* ArtistDetails styles */
.artist-details-modal {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;  /* Changed from all to just transform */
  z-index: 900;
  overflow-y: auto;
  bottom: 0;  /* Always stick to bottom */
}

.artist-details-modal.contracted {
  height: 25vh;
  transform: translateY(0);
}

.artist-details-modal.expanded {
  height: calc(100vh - 60px);
  transform: translateY(0);
}

.modal-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #f4a460;
  border-radius: 16px 16px 0 0;
  cursor: pointer;
  z-index: 1;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(139, 69, 19, 0.1);
}

.modal-header:hover {
  background-color: #e27b36;
}

.modal-header::before {
  content: '';
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 2px;
  transition: background-color 0.3s ease;
}

.modal-header:hover::before {
  background-color: rgba(255, 255, 255, 0.9);
}

.modal-header h2 {
  font-size: 1.2rem;
  color: #ffffff;
  margin: 8px 0 0 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.expand-text {
  font-size: 0.8em;
  color: #fff;
  font-weight: normal;
}

.expand-indicator {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.expand-indicator::before {
  content: '';
  width: 8px;
  height: 8px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}

.artist-details-modal.contracted .expand-indicator::before {
  transform: rotate(-135deg);
}

.artist-details-modal.expanded .expand-indicator::before {
  transform: rotate(45deg);
}

.modal-content {
  padding: 10px 10px;
}

.artist-metadata {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #666;
}

.artist-metadata .metadata-value {
  color: #666;
}
p.disambiguation {
  margin: 0px 0px 10px 0px; /* Added bottom margin */
  font-size: 0.8rem;
}

.artist-metadata .disambiguation {
  color: gray;
  margin-bottom: 8px; /* Adds vertical padding between disambiguation and country */
}

.artist-relationships h3 {
  margin-top: 15px;
  margin-bottom: 10px;
  color: #666;
}

.artist-relationships ul {
  list-style-type: none;
  padding-left: 0;
}

.artist-relationships .member-item {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f8f8f8;
  transition: background-color 0.2s ease;
}

.artist-relationships .member-item .member-name {
  font-weight: bold;
  color: #e27b36;
  cursor: pointer;
  margin-right: 5px;
}

.artist-relationships .member-item .shared-members-count {
  font-size: 0.8em;
  color: #666;
}

.artist-relationships .member-item .shared-members-list {
  margin-top: 5px;
  font-size: 0.9em;
}

.artist-relationships .member-item .shared-member {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
  color: #333;
  cursor: pointer;
}

.artist-relationships .member-item .shared-member:hover {
  text-decoration: underline;
}

.info-box {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.info-box p {
  margin: 0;
  flex: 1 1 auto;
}

.info-box button {
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  font-weight: bold;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .artist-relationships .member-item {
    padding: 8px;
  }

  .artist-relationships .member-item .shared-members-list {
    font-size: 0.8em;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .artist-details-modal {
    max-width: none;
    height: 130px;
  }

  .artist-details-modal.expanded {
    height: calc(100% - 60px); /* Updated: only account for header */
  }

  .visualization-container {
    height: calc(100vh - 260px) !important; /* Adjusted for new spacing */
  }
}

/* Desktop styles */
@media (min-width: 769px) {
  .visualization-container {
    max-width: 600px; /* Fixed width for desktop */
    margin: 0 auto; /* Center the container */
  }
}

.logo-image {
  width: 325px; /* Adjust size as needed */
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-box {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  margin-bottom: 10px;
}

.alert-box p {
  margin: 0;
  color: #721c24;
  font-size: 1rem;
}

.alert-link {
  color: #721c24;
  text-decoration: underline;
  font-weight: bold;
}

.alert-link:hover {
  color: #491217;
}

/* Add these styles if they don't already exist */
.search-bar-container {
  max-width: 600px;
  margin: 0 auto;
}

.search-bar {
  display: flex;
  align-items: center;
}

.search-input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
}

.search-button {
  padding: 10px;
  background-color: #007bff;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.search-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}



.clear-cache-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.clear-cache-button:hover {
  background-color: #c82333;
}

/* Add these styles to your existing CSS file */
.clear-cache-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.clear-cache-button {
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clear-cache-button:hover {
  background-color: #c82333;
}

.artist-lookup-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.graph-container {
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.graph-container svg {
  max-width: 100%;
  height: auto;
}

/* Add this to your existing CSS */
.node {
  cursor: pointer;
}

.node text {
  pointer-events: none;
}

.artist-relationships .shared-member-link {
  color: #e27b36;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s ease;
  margin-right: 10px;
  display: inline-block;
}

.artist-relationships .shared-member-link:hover {
  color: #d2691e;
  text-decoration: underline;
}

.artist-related-groups {
  width: 100%;
  max-width: 600px;
  height: 600px;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
  margin: 10px 0;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin-bottom: 5px;
}

.loading-message p {
  font-size: 14px;
  margin: 0;
  color: #8B4513;
}

@media (max-width: 768px) {
  .loading-message p {
    font-size: 12px;
  }

  .loading-spinner {
    width: 30px;
    height: 30px;
  }
}

.spotify-loading {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.spotify-loading .loading-text::after {
  content: '...';
  animation: ellipsis 1.5s infinite;
}

@keyframes ellipsis {
  0% { content: '.'; }
  33% { content: '..'; }
  66% { content: '...'; }
}

/* Add these styles at the end of your index.css file */

.groupling-lookup-wrapper {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.groupling-lookup-wrapper .visualization-container {
  touch-action: pan-x pan-y pinch-zoom;
}

.groupling-lookup-wrapper .graph-container {
  touch-action: none;
}

/* Add these styles at the end of the file */
.artist-relationships .member-item {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f8f8f8;
  transition: background-color 0.2s ease;
}

.artist-relationships .member-name {
  font-weight: bold;
  color: #e27b36;
  cursor: pointer;
  margin-right: 5px;
}

.artist-relationships .original-label {
  background-color: #e27b36;
  color: white;
  padding: 2px 4px;
  border-radius: 3px;
  font-size: 0.7em;
  margin-left: 5px;
}

.artist-relationships .role-info {
  margin-top: 5px;
  font-size: 0.8em;
}

.artist-relationships .roles {
  color: #666;
}

.artist-relationships .date-range {
  color: #999;
  font-style: italic;
  margin-left: 5px;
}

.view-related-groups-button {
  background-color: #e27b36;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 15px;
  transition: background-color 0.3s ease;
}

.view-related-groups-button:hover {
  background-color: #d2691e;
}

.view-related-groups-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.view-related-groups-button {
  background-color: #e27b36;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.view-related-groups-button:hover {
  background-color: #d2691e;
}

/* Graph Toggle Switch styles */
.graph-toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px auto;
  padding: 5px;
  background-color: #f4a460;
  border-radius: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: calc(100% - 32px);
  max-width: 350px;
}

.graph-toggle-switch {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 34px;
  margin: 0;
}

.graph-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.graph-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #8B4513;
  transition: .4s;
  border-radius: 34px;
}

.graph-toggle-slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 48%;  /* Adjust slider width to be slightly less than half */
  left: 2px;
  bottom: 2px;
  background-color: #ffffff;
  transition: .4s;
  border-radius: 30px;
}

input:checked + .graph-toggle-slider:before {
  transform: translateX(108%);  /* Adjust to match new width */
}

.graph-toggle-labels {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  pointer-events: none;
}

.graph-toggle-labels span {
  z-index: 1;
  transition: color 0.3s ease;
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;  /* Adjust padding */
  white-space: nowrap;  /* Prevent text wrapping */
  font-size: 14px;  /* Slightly smaller font */
}

.toggle-icon {
  width: 16px;  /* Slightly smaller icons */
  height: 16px;
  margin-right: 4px;  /* Reduce space after icon */
}

/* Toggle icon styles */
.toggle-icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

/* Specific margins for each label */
.graph-toggle-labels span:first-child {
  margin-left: 0px; /* Add left margin to the first label */
}

.graph-toggle-labels span:first-child .toggle-icon {
  margin-right: 5px; /* Space between icon and "Related Groups" text */
}

.graph-toggle-labels span:last-child {
  margin-right: 15px; /* Add right margin to the second label */
}

.graph-toggle-labels span:last-child .toggle-icon {
  margin-right: 8px; /* Space between icon and "Relationships" text */
}

/* Toggle state styles */
input:checked + .graph-toggle-slider .graph-toggle-labels .active,
input:checked + .graph-toggle-slider .graph-toggle-labels .active .toggle-icon {
  color: #ffffff;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

input:checked + .graph-toggle-slider .graph-toggle-labels .inactive,
input:checked + .graph-toggle-slider .graph-toggle-labels .inactive .toggle-icon {
  color: #8B4513;
  filter: brightness(0) saturate(100%) invert(22%) sepia(57%) saturate(681%) hue-rotate(346deg) brightness(92%) contrast(95%);
}

input:not(:checked) + .graph-toggle-slider .graph-toggle-labels .active,
input:not(:checked) + .graph-toggle-slider .graph-toggle-labels .active .toggle-icon {
  color: #ffffff;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

input:not(:checked) + .graph-toggle-slider .graph-toggle-labels .inactive,
input:not(:checked) + .graph-toggle-slider .graph-toggle-labels .inactive .toggle-icon {
  color: #8B4513;
  filter: brightness(0) saturate(100%) invert(22%) sepia(57%) saturate(681%) hue-rotate(346deg) brightness(92%) contrast(95%);
}

/* Expand button styles */
.graph-toggle-expand {
  width: 350px;
  height: 34px;
  background-color: #8B4513;
  color: #ffffff;
  border: none;
  border-radius: 34px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.graph-toggle-expand:hover {
  background-color: #a0522d;
}

/* Media query for smaller screens */
@media (max-width: 480px) {
  .graph-toggle-container {
    width: 400px;  /* Match new width */
  }

  .graph-toggle-switch {
    width: 400px;
  }
}

.active-artist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
}

.active-artist-name {
  color: #8B4513;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: auto;  /* Push to the left */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Search bar collapsed state */
.search-bar-collapsed {
  background: #f4a460;
  border-radius: 25px;
  padding: 6px;  /* Reduced from 8px 16px */
  transition: all 0.3s ease;
  cursor: pointer;
  margin-left: 15px;
  width: fit-content;  /* Add this to prevent stretching */
  display: flex;  /* Add this for better button control */
  align-items: center;  /* Center vertically */
}

.search-bar-collapsed .search-button {
  background: none;
  border: none;
  padding: 4px;  /* Reduced padding */
  cursor: pointer;
  display: flex;  /* Add this */
  align-items: center;  /* Center vertically */
  justify-content: center;  /* Center horizontally */
}

.search-bar-collapsed .search-button img {
  width: 28px;  /* Fixed size */
  height: 28px;  /* Fixed size */
  filter: brightness(0) saturate(100%);  /* Make icon white */
}

/* Keep hover effects */
.search-bar-collapsed:hover {
  background: #e27b36;
  transform: translateY(-1px);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

/* Remove the active artist name from collapsed state since it's shown in the header */
.search-bar-collapsed .active-artist-name {
  display: none;
}

/* Add disabled state styles */
.graph-toggle-container.disabled {
  opacity: 1;  /* Keep full opacity */
}

.graph-toggle-container.disabled .disabled-text {
  opacity: 0.5;  /* Make the inactive option more faded */
  cursor: not-allowed;
}

.graph-toggle-container.disabled input:checked + .graph-toggle-slider:before {
  cursor: not-allowed;
}

.graph-toggle-container.disabled .graph-toggle-expand {
  cursor: not-allowed;
  background-color: #ccc;
}

.graph-toggle-container.disabled:hover .graph-toggle-expand {
  background-color: #ccc;
}

.disambiguation {
  margin: 0px 0px 10px 0px;
  font-size: 0.9rem;
  color: #666;
}

.disambiguation strong {
  color: #333;
  margin-right: 4px;
  font-size: 1.2rem;  /* Increased from default */
}

/* Update existing elements with gradients and animations */
.graph-toggle-container {
  background: linear-gradient(145deg, #f4a460, #e27b36);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.graph-toggle-container:not(.disabled):hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(139, 69, 19, 0.2);
}

.modal-header {
  background: linear-gradient(145deg, #f4a460, #e27b36);
  transition: transform 0.2s ease;
}

.modal-header:hover {
  background: linear-gradient(145deg, #e27b36, #d2691e);
  transform: translateY(-1px);
}

.member-item {
  background: linear-gradient(145deg, #fff, #f8f8f8);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.member-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(139, 69, 19, 0.1);
}

.search-bar-collapsed {
  background: linear-gradient(145deg, #f4a460, #e27b36);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.search-bar-collapsed:hover {
  background: linear-gradient(145deg, #e27b36, #d2691e);
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(139, 69, 19, 0.2);
}

.view-related-groups-button {
  background: linear-gradient(145deg, #e27b36, #d2691e);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.view-related-groups-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(139, 69, 19, 0.2);
}

.artist-tabs {
  display: flex;
  gap: 0;
  margin: 15px 0 20px;
  padding: 4px 4px 0 4px;
  position: relative;
  border-bottom: 2px solid #e27b36;  /* Match our theme color */
}

.tab-button {
  flex: 1;
  padding: 12px 20px;
  border: none;
  background: none;
  color: #666;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  font-size: 0.95rem;
  border-radius: 8px 8px 0 0;  /* Rounded top corners */
}

.tab-button.active {
  background: #e27b36;  /* Match border color */
  color: white;
  font-weight: 600;
  margin-bottom: -2px;  /* Overlap the border */
}

.tab-button:hover:not(.active) {
  background: #fff0e0;
  color: #8B4513;
}

/* Remove the previous underline styles */
.tab-button::after,
.tab-button.active::after {
  display: none;
}

.grouplings-container {
  padding: 10px;
  height: 100%;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .grouplings-container {
    padding: 15px;
  }
}

/* Add styles for disabled option */
.graph-toggle-container.disabled .disabled-option {
  text-decoration: line-through;
  opacity: 0.5;
  position: relative;
}

.graph-toggle-container.disabled .disabled-option::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(139, 69, 19, 0.1);  /* Light brown overlay */
  border-radius: 15px;
  pointer-events: none;
}

.graph-toggle-container.disabled .disabled-option .toggle-icon {
  opacity: 0.5;
}

/* Error Modal styles */
.error-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
}

.error-modal {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 90%;
  width: 300px;
}

.error-modal p {
  margin: 0 0 15px 0;
  color: #333;
  font-size: 1rem;
}

.error-modal button {
  background: #e27b36;
  color: white;
  border: none;
  padding: 8px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.error-modal button:hover {
  background: #d2691e;
}

.home-page {
  min-height: calc(100vh - 60px); /* Account for header */
  display: flex;
  flex-direction: column;
  background: #fff;
}

.welcome-content {
  padding: 80px 20px 40px;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  flex: 1;
}

/* Add new styles for visualization header */
.visualization-header {
  background: rgb(242, 242, 242);
  padding: 15px;
  box-shadow: 0 4px 4px rgba(139, 69, 19, 0.1);
  margin-bottom: 5px;
  margin-top: 5px;
  width: 100%;
  position: relative;
  z-index: 100;
}

/* Update active artist header styles */
.active-artist-header {
  display: flex;
  align-items: center;
  max-width: 600px;
  padding: 0;
}

.active-artist-name {
  color: #8B4513;
  font-size: 1.5rem;
  font-weight: bold;
  flex: 1;  /* Take up available space */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;  /* Space between name and search button */
  min-width: 0;  /* Allow flex item to shrink below content size */
  display: flex;
  align-items: center; /* Vertically centers content */
}

/* Update search bar styles */
.search-bar-container {
  position: absolute;
  z-index: 1500;
  width: 90%;
  max-width: 600px;
  margin-top: 7px;
}

.search-bar-collapsed {
  margin-left: auto;
  width: 32px;
  height: 32px;
}

/* Add mobile-specific styles */
@media (max-width: 768px) {
  .search-bar-container {
    width: 95%;  /* Wider on mobile */
  }

  .search-bar {
    width: 100%;  /* Full width of container */
  }

  .search-bar input {
    width: 100%;  /* Full width of container minus button */
  }
}

/* Home page specific styles */
.home-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;  /* Remove all margins */
  padding: 0; /* Remove all padding */
}


.home-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 0 20px;
}

.home-page .welcome-content {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  padding: 0;
  flex: 1;
}

.home-page .welcome-content h1 {
  color: var(--text-primary);
  font-size: 2.0rem;
  line-height: 1.2;
  margin-top: 0;  /* Remove top margin */
  margin-bottom: 30px;
  font-weight: 800;
  letter-spacing: -0.02em;
}

.home-page .welcome-content .subtitle {
  color: var(--text-secondary);
  font-size: 1.0rem;
  line-height: 1.6;
  margin-bottom: 40px;
  font-weight: 400;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
}

.home-search-section {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.home-page .search-bar {
  width: 100%;
  margin: 0 auto;
}

/* Ensure search bar is always expanded on home page */
.home-page .search-bar-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

/* Update highlight and strikethrough styles */
.highlight {
  color: var(--bacon-primary);
  font-weight: inherit;
  position: relative;
  display: inline;
}

.strikethrough {
  position: relative;
  display: inline;
}

.strikethrough::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 5px;
  background-color: var(--bacon-dark);
  transform: rotate(-10deg);
}

/* Update home page layout */
.home-page {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  padding: 60px 20px 0;
  background: #fff;
}

.welcome-content {
  flex: 1;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 40px;
}

/* Tutorial Modal Styles */
.tutorial-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.tutorial-modal {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  max-height: 90vh; /* Limit height to 90% of viewport height */
  position: relative;
  display: flex;
  flex-direction: column;
}

.tutorial-modal-header {
  padding: 5px 20px 5px;
  border-bottom: 1px solid #e5e7eb;
  flex-shrink: 0; /* Prevent header from shrinking */
}

.tutorial-modal-title {
  font-size: 24px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.tutorial-modal-subtitle {
  color: #6b7280;
  font-size: 14px;
}

.tutorial-modal-body {
  padding: 5px 10px;
  overflow-y: auto; /* Make content scrollable */
  flex-grow: 1; /* Allow body to grow and scroll */
}

.tutorial-modal-section {
  margin-bottom: 20px;
}

.tutorial-modal-section-title {
  font-size: 16px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 8px;
}

.tutorial-modal-text {
  color: #4b5563;
  font-size: 14px;
  line-height: 1.5;
}

.tutorial-modal-example-container {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 12px;
  margin-top: 8px;
}

.tutorial-modal-search-row {
  display: flex;
  align-items: center;
  gap: 12px;
}

.tutorial-modal-search-example {
  background-color: #f4a460;
  padding: 6px;
  border-radius: 20px;
  width: fit-content;
}

.tutorial-modal-search-icon {
  width: 28px;
  height: 28px;
  filter: brightness(0) invert(1);
}

.tutorial-modal-toggle-example {
  background-color: #f4a460;
  border-radius: 30px;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  width: 280px;
  margin: 8px 0;
}

.tutorial-modal-toggle-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
}

.tutorial-modal-toggle-icon {
  width: 16px;
  height: 16px;
  filter: brightness(0) invert(1);
}

.tutorial-modal-views-container {
  display: flex;
  gap: 16px;
  margin-top: 12px;
}

.tutorial-modal-view-box {
  flex: 1;
}

.tutorial-modal-footer {
  padding: 5px 10px;
  border-top: 1px solid #e5e7eb;
  background-color: #f9fafb;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 12px 12px; /* Match the modal's border radius */
}

.tutorial-modal-button-secondary {
  color: #6b7280;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 12px;
  transition: color 0.2s;
}

.tutorial-modal-button-secondary:hover {
  color: #374151;
}

.tutorial-modal-button-primary {
  background-color: #2563eb;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.tutorial-modal-button-primary:hover {
  background-color: #1d4ed8;
}

.tutorial-modal-close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  color: #9ca3af;
  padding: 8px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.tutorial-modal-close-button:hover {
  background-color: #f3f4f6;
  color: #4b5563;
}

/* Tutorial Help Button */
.tutorial-help-button {
  display: flex;
  align-items: center;
  margin-left: 8px;
  padding: 4px;
  cursor: pointer;
  border: none;
  background: none;
  transition: transform 0.2s ease;
}

.tutorial-help-button:hover {
  transform: scale(1.1);
}

.tutorial-help-button img {
  width: 24px;
  height: 24px;
}

/* Tutorial FAB Styles */
.tutorial-fab {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #f4a460;
  border: 2px solid #8B4513;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  z-index: 1000;
  position: fixed;
  bottom: 4px;  /* Closer to bottom */
  right: 4px;   /* Closer to right */
}

.tutorial-fab:hover {
  background: #e27b36;
  border-color: #6b3010;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .tutorial-fab {
    width: 44px;
    height: 44px;
  }

  .tutorial-fab span {
    font-size: 20px;
  }
}

/* Update Tutorial FAB Styles */
.tutorial-fab-container {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 1000;
}

.tutorial-fab {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: #f4a460;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.tutorial-fab:hover {
  transform: scale(1.1);
  background: #e27b36;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.tutorial-fab-close {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #666;
  color: white;
  border: none;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  opacity: 0;
  transform: scale(0.8);
}

.tutorial-fab-container:hover .tutorial-fab-close {
  opacity: 1;
  transform: scale(1);
}

.tutorial-fab-close:hover {
  background: #444;
}

/* Add mobile adjustments */
@media (max-width: 768px) {
  .tutorial-fab-container {
    bottom: 16px;
    right: 16px;
  }
  
  .tutorial-fab {
    width: 48px;
    height: 48px;
  }
}

/* Update mobile adjustments for Tutorial FAB */
@media (max-width: 768px) {
  .tutorial-fab-container {
    bottom: calc(25vh + 24px); /* Position above the contracted details panel */
    right: 16px;
  }
  
  .tutorial-fab {
    width: 44px; /* Slightly smaller on mobile */
    height: 44px;
  }

  .tutorial-fab span {
    font-size: 20px; /* Slightly smaller emoji */
  }

  /* When details panel is expanded */
  .artist-details-modal.expanded ~ .tutorial-fab-container {
    bottom: 24px; /* Move to bottom when details are expanded */
    right: 16px;
  }

  /* Adjust close button for easier touch */
  .tutorial-fab-close {
    width: 24px;
    height: 24px;
    top: -10px;
    right: -10px;
    font-size: 14px;
  }
}

/* Add to your existing search bar styles */
.search-button.has-results {
  background-color: #f4a460;
}

.search-button.has-results img {
  filter: brightness(0) invert(1); /* Make the icon white */
}


/* Update search suggestions to match helper text style */
.search-suggestions {
  top: calc(100% + 1px);
  width: 100%;
  background-color: white;
  border-left: 2px solid #f4a460;
  border-right: 2px solid #f4a460;
  border-bottom: 2px solid #f4a460;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.search-suggestions li {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.search-suggestions li:hover {
  background-color: #fff0e0;
}


/* Home page specific styles */
.home-page {
  position: relative; /* Create positioning context */
}

.home-search-section {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  height: 60px; /* Fixed height to prevent layout shifts */
}

/* Override search container positioning for home page */
.home-page .search-bar-container {
  position: relative;
  width: 100%;
  margin: 20px auto;
}

/* Make search results float over content */
.home-page .search-results-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  z-index: 2000;
}

/* Ensure subtitle stays in place */
.home-page .subtitle {
  position: relative;
  z-index: 1;
}

/* Logo positioning for home page */
.home-page .logo-image {
  width: 325px;
  margin-bottom: 20px; /* Add space below logo */
}

/* Keep original logo positioning for visualization page */
.visualization-page .logo-image {
  width: 325px;
  margin: 0;
}

/* Update home page content spacing */
.home-page .welcome-content {
  padding-top: 20px; /* Reduce top padding to account for new logo spacing */
}

/* Header styles for home page */
.home-page .header {
  position: relative;  /* Change from fixed to relative for home page */
  height: auto;
}

.home-page .header-content {
  padding-top: 80px;  /* Reduced padding */
  padding-bottom: 20px;
}

/* Welcome content positioning */
.home-page .welcome-content {
  padding-top: 0;
  max-width: 600px;
  margin: 0 auto;
}

/* Remove duplicate home-header styles */
.home-header {
  display: none;
}

/* Specific adjustments for smaller screens */
@media (max-width: 375px) {  /* iPhone SE and similar */
  .graph-toggle-container {
    max-width: 300px;
  }

  .graph-toggle-labels span {
    font-size: 12px;  /* Smaller font */
  }

  .toggle-icon {
    width: 14px;  /* Smaller icons */
    height: 14px;
    margin-right: 2px;  /* Less spacing */
  }

  .graph-toggle-slider:before {
    width: 46%;  /* Slightly narrower slider */
  }

  input:checked + .graph-toggle-slider:before {
    transform: translateX(115%);  /* Adjusted for narrower slider */
  }
}

/* Even smaller screens */
@media (max-width: 320px) {  /* Galaxy S8+ and similar */
  .graph-toggle-container {
    max-width: 280px;
  }

  .graph-toggle-labels span {
    font-size: 11px;
  }
}

/* Footer styles */
.home-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: linear-gradient(145deg, var(--bacon-primary), var(--bacon-accent));
  padding: 15px 0;
  z-index: 1000;
}

.footer-content {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer-content p {
  margin: 0;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.95rem;
}

.footer-content a {
  color: white;
  text-decoration: none;
  font-weight: 500;
}

.footer-content a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .home-footer {
    padding: 12px 0;
  }

  .footer-content p {
    font-size: 0.85rem;
  }
}


